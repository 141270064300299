import React from 'react';
import { breadCrumbType, priceEnum, storageKey } from '@/Interface/interface';
import breadCrumbConst from '@/utils/constants';
import Cookies from 'js-cookie';
import { CountryList } from './Country-list';
import fs from 'fs';

export function setCompareDeviceBreadCrumb(
  setBreadCrumbs: React.Dispatch<
    React.SetStateAction<breadCrumbType[] | undefined>
  >,
  deviceName: any,
  setDeviceName?: any,
) {
  const compareDeviceSlug = sessionStorage.getItem(storageKey.compareRouteLink);
  if (!compareDeviceSlug) {
    setBreadCrumbs([
      {
        ...breadCrumbConst.comparePhones,
        isActive: true,
      },
    ]);
    if (setDeviceName) {
      setDeviceName(['']);
    }
    return;
  }

  const compareDeviceName = Array.isArray(deviceName)
    ? deviceName.join(' vs ')
    : deviceName;

  setBreadCrumbs([
    {
      ...breadCrumbConst.comparePhones,
      isActive: false,
    },
    {
      hrefLink: `/${compareDeviceSlug}`,
      title: compareDeviceName,
      isActive: true,
    },
  ]);
}

export function getCurrentMonthAndYear() {
  const date = new Date();
  const month = date.toLocaleString('en-US', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`; // Output: June 2024
}

export const getCountryCode = () => {
  let countryFromCookie = Cookies.get('CountryCode');
  if (!countryFromCookie) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    countryFromCookie = CountryList[timeZone] || 'US';
    Cookies.set('CountryCode', countryFromCookie, { expires: 7 });
  }
  return (
    Object.keys(priceEnum).includes(countryFromCookie)
      ? countryFromCookie
      : 'US'
  ) as keyof typeof priceEnum;
};

export const readJSONFile = (filePath: string, defaultReturn: any = null) => {
  try {
    return JSON.parse(fs.readFileSync(filePath, 'utf8'));
  } catch (error) {
    return defaultReturn;
  }
};

export const writeJSONFile = (filePath: string, defaultJSON: any = {}) => {
  try {
    fs.writeFileSync(filePath, JSON.stringify(defaultJSON));
  } catch (error) {
    console.error('Error: ', error);
  }
};
