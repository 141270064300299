import {
  convertToMBOrTB,
  getCameraResolutionCompareDevice,
  getOverviewVideo,
  getSelfieCameraCompareDevice,
  moveObjectToLast,
  removeNull,
} from '@/Configuration/Service';
import {
  ChargingSpecification,
  chargingSpecificationArrayType,
  CommunicationPositioningArray,
  FeatureItem,
  KeyAndValue,
  MainCameraData,
  MemorySpecificationArrayType,
  PlatformSpecificationArrayType,
  SelfieCameraData,
  VideoSpecificationArrayType,
} from '@/Interface/interface';
import React from 'react';

export const getBodySpecification = (bodySpecificationArray: any) => {
  const data = bodySpecificationArray.find(
    (res: any) => res.key === 'body_dimensions',
  )?.value;
  if (data?.length !== 0) {
    const unFolded = data?.find((res: any) => res?.type === 'Unfolded');
    const folded = data?.find((res: any) => res?.type === 'Folded');

    const getDimensionString = (dimension: string) => {
      const unfoldedValue = unFolded?.[dimension];
      const foldedValue = folded?.[dimension];
      const unfoldedValueInches = unFolded?.[`${dimension}_inches`];
      const foldedValueInches = folded?.[`${dimension}_inches`];

      if (
        unfoldedValue ||
        unfoldedValueInches ||
        foldedValue ||
        foldedValueInches
      ) {
        return (
          `${folded && (unfoldedValue || unfoldedValueInches) ? 'Unfolded: ' : ''}` +
          (unfoldedValue ? `${unfoldedValue} mm ` : '') +
          (unfoldedValueInches ? `(${unfoldedValueInches} inch)` : '') +
          ` ` +
          (folded && (foldedValue || foldedValueInches)
            ? `Folded: ${foldedValue ? `${foldedValue} mm` : ''}` +
              (foldedValueInches ? `(${foldedValueInches} inch)` : '')
            : '')
        );
      }
    };

    const heightDimension = getDimensionString('body_dimensions_height');
    const widthDimension = getDimensionString('body_dimensions_width');
    const depthDimension = getDimensionString('body_dimensions_depth');

    const dimensions = (
      <>
        {heightDimension && (
          <p>
            <span className="font-semibold">Height:</span> {heightDimension}
          </p>
        )}

        {widthDimension && (
          <p>
            <span className="font-semibold">Width:</span> {widthDimension}
          </p>
        )}

        {depthDimension && (
          <p>
            <span className="font-semibold">Depth:</span> {depthDimension}
          </p>
        )}
      </>
    );

    return dimensions;
  }
};

export const getOSSpecification = (OSSpecificationArray: KeyAndValue[]) => {
  const getOSSpecs = OSSpecificationArray.reduce((key: any, res: any) => {
    switch (res.key) {
      case 'platform_os_type':
        key.osType = res.value;
        break;
      case 'platform_os_version':
        key.osVersion = res.value;
        break;
      case 'platform_os_upgradable_version':
        key.upgradableVersion = res.value;
        break;
      case 'platform_os_ui':
        key.customUI = res.value;
        break;
    }
    return key;
  }, {});

  const OSInformation = (
    <>
      {(getOSSpecs.osType || getOSSpecs.osVersion) && (
        <p>
          <span className="font-semibold">Current Version:</span>{' '}
          {`${getOSSpecs.osType} ${getOSSpecs.osVersion}`}
        </p>
      )}

      {getOSSpecs.upgradableVersion && (
        <p>
          <span className="font-semibold">Upgradable To:</span>{' '}
          {getOSSpecs.upgradableVersion}
        </p>
      )}

      {getOSSpecs.customUI && (
        <p>
          <span className="font-semibold">Custom UI:</span>{' '}
          {getOSSpecs.customUI}
        </p>
      )}
    </>
  );

  return OSInformation;
};

export const getChipSetSpecification = (chipSetSpecs: KeyAndValue[]) => {
  const chipSetObj = chipSetSpecs.reduce((key: any, res: any) => {
    switch (res.key) {
      case 'chipset_processor':
        key.processor = res.value;
        break;
      case 'chipset_model':
        key.model = res.value;
        break;
    }
    return key;
  }, {});

  if (!chipSetObj.processor && !chipSetObj.model) {
    return [];
  }

  const chipSetInformation = (
    <>
      {(chipSetObj.processor || chipSetObj.model) && (
        <p>
          <span className="font-semibold">Processor: </span>
          {`${chipSetObj.processor} ${chipSetObj.model}`}
        </p>
      )}
    </>
  );
  return chipSetInformation;
};

export const getDisplaySpecification = (displaySpecsArray: KeyAndValue[]) => {
  const getDisplaySpecs = displaySpecsArray.reduce((key: any, res: any) => {
    switch (res.key) {
      case 'display_screen_size':
        key.screenSize = res.value;
        break;
      case 'display_screen_area':
        key.screenArea = res.value;
        break;
      case 'display_screen_to_body_ratio':
        key.screenBodyRatio = res.value;
        break;
    }
    return key;
  }, {});
  if (
    !getDisplaySpecs.screenSize &&
    !getDisplaySpecs.screenArea &&
    !getDisplaySpecs.screenBodyRatio
  ) {
    return [];
  }
  const displayInformation = (
    <>
      {getDisplaySpecs.screenSize && (
        <p>
          <span className="font-semibold">Screen Size: </span>
          {`${getDisplaySpecs.screenSize}`}
        </p>
      )}

      {getDisplaySpecs.screenArea && (
        <p>
          <span className="font-semibold">Surface Area: </span>
          {`${getDisplaySpecs.screenArea} cm²`}
        </p>
      )}

      {getDisplaySpecs.screenBodyRatio && (
        <p>
          <span className="font-semibold">Screen To Body Ratio: </span>
          {`${getDisplaySpecs.screenBodyRatio}%`}
        </p>
      )}
    </>
  );

  return displayInformation;
};

export const getDisplayProtectionInformation = (
  displayProtectionArray: KeyAndValue[],
) => {
  const getDisplayProtectionSpecs = displayProtectionArray.reduce(
    (key: any, res: any) => {
      switch (res.key) {
        case 'front_display_protection':
          key.frontDisplayProtection = res.value;
          break;
        case 'back_display_protection':
          key.backDisplayProtection = res.value;
          break;
      }
      return key;
    },
    {},
  );
  if (
    !getDisplayProtectionSpecs.frontDisplayProtection &&
    !getDisplayProtectionSpecs.backDisplayProtection
  ) {
    return [];
  }
  const displayProtection = (
    <>
      {getDisplayProtectionSpecs.frontDisplayProtection && (
        <p>
          <span className="font-semibold">Front Protection: </span>
          {getDisplayProtectionSpecs.frontDisplayProtection}
        </p>
      )}

      {getDisplayProtectionSpecs.backDisplayProtection && (
        <p>
          <span className="font-semibold">Back Protection: </span>
          {`${getDisplayProtectionSpecs.backDisplayProtection} cm²`}
        </p>
      )}
    </>
  );

  return displayProtection;
};

const getChargingSpecificationString = (
  ChargingSpecification: ChargingSpecification,
) => {
  const specification = [
    ChargingSpecification.power_output,
    ChargingSpecification.charging_specifications &&
      ` ${ChargingSpecification.charging_specifications}`,
    ChargingSpecification.power_delivery,
    ChargingSpecification.power_supply,
    ChargingSpecification.quick_charge,
    ChargingSpecification.advertised_charging_time,
  ].filter(Boolean);
  return specification;
};

export const getChargingSpecification = (
  chargingSpecificationArray: chargingSpecificationArrayType[],
) => {
  const getChargingType = chargingSpecificationArray.find(
    (res: { key: string }) => res.key === 'charging_specifications',
  )?.value;

  if (!getChargingType?.length) {
    return [];
  }

  return getChargingType.map((res) => getChargingSpecificationString(res));
};

export const getCoverDisplaySpecification = (
  coverDisplayArray: KeyAndValue[],
) => {
  const CoverDisplayValues = coverDisplayArray.reduce((key: any, res: any) => {
    switch (res.key) {
      case 'cover_display_type':
        key.coverDisplayType = res.value;
        break;
      case 'cover_display_screen_size':
        key.coverDisplayScreenSize = res.value;
        break;
      case 'cover_display_resolution_width':
        key.coverDisplayResolutionWidth = res.value;
        break;
      case 'cover_display_resolution_height':
        key.coverDisplayResolutionHeight = res.value;
        break;
      case 'cover_display_ppi':
        key.coverDisplayPPI = res.value;
        break;
      case 'cover_display_glass':
        key.coverDisplayGlass = res.value;
        break;
      case 'cover_display_colors':
        key.coverDisplayColors = res.value;
        break;
      case 'cover_display_sound':
        key.coverDisplaySound = res.value;
        break;
      case 'cover_display_refresh_rate':
        key.coverDisplayRefreshRate = res.value;
        break;
      case 'cover_display_peak_brightness':
        key.coverDisplayPeakBrightness = res.value;
        break;
    }
    return removeNull(key, '');
  }, {});

  if (
    !CoverDisplayValues.coverDisplayType &&
    !CoverDisplayValues.coverDisplayScreenSize &&
    !CoverDisplayValues.coverDisplayResolutionWidth &&
    !CoverDisplayValues.coverDisplayResolutionHeight &&
    !CoverDisplayValues.coverDisplayPPI &&
    !CoverDisplayValues.coverDisplayGlass &&
    !CoverDisplayValues.coverDisplayColors &&
    !CoverDisplayValues.coverDisplaySound &&
    !CoverDisplayValues.coverDisplayRefreshRate &&
    !CoverDisplayValues.coverDisplayPeakBrightness
  ) {
    return null;
  }

  const coverDisplay = (
    <>
      {CoverDisplayValues.coverDisplayType && (
        <p>
          <span className="font-semibold">Type: </span>
          {CoverDisplayValues.coverDisplayType}
        </p>
      )}
      {CoverDisplayValues.coverDisplayScreenSize && (
        <p>
          <span className="font-semibold">Size: </span>
          {`${CoverDisplayValues.coverDisplayScreenSize} inches`}
        </p>
      )}
      {CoverDisplayValues.coverDisplayResolutionWidth && (
        <p>
          <span className="font-semibold">Resolution: </span>
          {`${CoverDisplayValues.coverDisplayResolutionWidth} x ${CoverDisplayValues.coverDisplayResolutionHeight} pixels`}
        </p>
      )}
      {CoverDisplayValues.coverDisplayPPI && (
        <p>
          <span className="font-semibold">Pixel Density: ~ : </span>
          {`${CoverDisplayValues.coverDisplayPPI} pixels per inch (ppi)`}
        </p>
      )}
      {CoverDisplayValues.coverDisplayGlass && (
        <p>
          <span className="font-semibold">Protection: </span>
          {CoverDisplayValues.coverDisplayGlass}
        </p>
      )}
      {CoverDisplayValues.coverDisplayColors && (
        <p>
          <span className="font-semibold">Color: </span>
          {CoverDisplayValues.coverDisplayColors}
        </p>
      )}
      {CoverDisplayValues.coverDisplaySound && (
        <p>
          <span className="font-semibold">Sound: </span>
          {CoverDisplayValues.coverDisplaySound}
        </p>
      )}
      {CoverDisplayValues.coverDisplayRefreshRate && (
        <p>
          <span className="font-semibold">Refresh rate: </span>
          {CoverDisplayValues.coverDisplayRefreshRate}
        </p>
      )}
      {CoverDisplayValues.coverDisplayPeakBrightness && (
        <p>
          <span className="font-semibold">Peak brightness: </span>
          {CoverDisplayValues.coverDisplayPeakBrightness}
        </p>
      )}
    </>
  );

  return coverDisplay;
};

export const displayInformationForOverviewField = (
  displayInformationArray: KeyAndValue[],
) => {
  if (displayInformationArray !== undefined) {
    const displayInformation = displayInformationArray.reduce(
      (key: any, res: any) => {
        switch (res.key) {
          case 'display_screen_size':
            key.displayScreenSize = res.value;
            break;
          case 'display_resolution_width':
            key.displayScreenWidth = res.value;
            break;
          case 'display_resolution_height':
            key.displayScreenHeight = res.value;
            break;
          case 'display_refresh_rate':
            key.displayRefreshRate = res.value;
            break;
        }
        return key;
      },
      {},
    );

    const displayData = (
      <>
        {displayInformation.displayScreenSize && (
          <p>
            <span className="font-semibold">Screen Size: </span>
            {`${displayInformation.displayScreenSize} inches`}
          </p>
        )}

        {(displayInformation.displayScreenWidth ||
          displayInformation.displayScreenHeight) && (
          <p>
            {` (${displayInformation.displayScreenWidth} X ${displayInformation.displayScreenHeight} pixels)`}
          </p>
        )}

        {displayInformation.displayScreenSize && (
          <p>
            {displayInformation.displayRefreshRate !== 0 &&
              `${displayInformation.displayRefreshRate} Refresh rate`}
          </p>
        )}
      </>
    );
    return displayData;
  }
};

export const cameraInformationForOverviewField = (
  cameraData: MainCameraData[],
  selfieCamera: SelfieCameraData[],
) => {
  const cameraInformation = cameraData?.reduce((key: any, res: any) => {
    switch (res.key) {
      case 'main_camera_data':
        key.mainCameraData = res.value;
        break;
      case 'main_camera_type':
        key.mainCameraType = res.value;
        break;
      case 'main_camera_video':
        key.mainCameraVideo = res.value;
        break;
    }
    return key;
  }, {});

  const displayData = (
    <>
      {cameraInformation?.mainCameraData && (
        <p>
          {`${getCameraResolutionCompareDevice(cameraInformation.mainCameraData)} MP (${cameraInformation.mainCameraType} Camera)`}
        </p>
      )}

      {selfieCamera && (
        <p>
          {`${removeNull(
            getSelfieCameraCompareDevice(selfieCamera, 'selfie_camera_data'),
            '',
          )} MP Selfie`}
        </p>
      )}

      {cameraData && (
        <p>
          {`${removeNull(
            getOverviewVideo(cameraInformation.mainCameraVideo),
            '',
          )} Video`}
        </p>
      )}
    </>
  );
  return displayData;
};

export const OSInformationForOverviewField = (
  softwareAndHardware: KeyAndValue[],
) => {
  if (softwareAndHardware !== undefined) {
    const getOSSpecs = softwareAndHardware.reduce((key: any, res: any) => {
      switch (res.key) {
        case 'platform_os_type':
          key.osType = res.value;
          break;
        case 'platform_os_version':
          key.osVersion = res.value;
          break;
        case 'platform_os_upgradable_version':
          key.upgradableVersion = res.value;
          break;
        case 'platform_os_ui':
          key.customUI = res.value;
          break;
      }
      return key;
    }, {});
    const OSInformation = (
      <>
        {(getOSSpecs.osType || getOSSpecs.osVersion) && (
          <p>{`${getOSSpecs.osType} ${getOSSpecs.osVersion}`}</p>
        )}
        {getOSSpecs.customUI && <p>{getOSSpecs.customUI}</p>}
      </>
    );
    return OSInformation;
  }
};

export const batteryInformationForOverviewField = (
  batteryData: KeyAndValue[],
) => {
  if (batteryData !== undefined) {
    const getBatterySpecs = batteryData.reduce((key: any, res: any) => {
      switch (res.key) {
        case 'battery_type':
          key.batteryType = res.value;
          break;
        case 'battery_capacity':
          key.batteryCapacity = res.value;
          break;
      }
      return key;
    }, {});
    const batteryInformation = (
      <>
        {getBatterySpecs.batteryType && <p>{getBatterySpecs.batteryType}</p>}

        {getBatterySpecs.batteryCapacity && (
          <p>{`${getBatterySpecs.batteryCapacity} mAh`}</p>
        )}
      </>
    );
    return batteryInformation;
  }
};

export const getColorAndSoundData = (
  displayAndSoundSpecsArray: FeatureItem[],
  key: string,
) => {
  return displayAndSoundSpecsArray
    ?.find((res) => res.key === key)
    ?.value.map((item) => {
      return item.name;
    })
    .join(', ');
};

export const getBodyWeight = (bodyWeightArray: KeyAndValue[]) => {
  const bodyWeight = bodyWeightArray?.find(
    (res) => res?.key === 'body_weight',
  )?.value;
  const bodyWeightOz = bodyWeightArray?.find(
    (res) => res?.key === 'body_weight_oz',
  )?.value;

  if (bodyWeight && bodyWeight !== null) {
    const formattedWeight = removeNull(bodyWeight, 'g ');
    const formattedWeightOz = removeNull(bodyWeightOz, ' oz)');

    return `${formattedWeight}${formattedWeightOz ? ` (${formattedWeightOz}` : ''}`;
  }

  return '';
};

export const getPlatformCPUInformation = (
  platformSpecification: PlatformSpecificationArrayType[],
  key: string,
) => {
  const data = platformSpecification
    ?.find((res) => res.key === key)
    ?.value.map((item) => {
      return `${removeNull(item?.cores?.trim(), ' ')}${removeNull(
        item?.frequency?.trim(),
        '',
      )} ${removeNull(item.architecture, '')}`;
    });
  return data?.join(', ');
};

export const getMemoryInformation = (
  memorySpecification: MemorySpecificationArrayType[],
  key: string,
) => {
  const formattedStrings = memorySpecification
    ?.find((res) => res?.key === key)
    ?.value?.map((item) => {
      return `${removeNull(
        convertToMBOrTB(item?.memory_internal_storage),
        '',
      )} ${removeNull(convertToMBOrTB(item?.memory_internal_ram), ' RAM')}`;
    });
  return formattedStrings && formattedStrings.join(', ');
};

export const getFilteredCameraAndColorFeatures = (
  CameraAndColorFeaturesArray: FeatureItem[],
  key: string,
) => {
  if (CameraAndColorFeaturesArray !== undefined) {
    return String(
      CameraAndColorFeaturesArray?.find((res) => res?.key === key)
        ?.value?.map((item) => `${item.name}`)
        .filter(Boolean)
        ?.join(', '),
    );
  } else {
    return '';
  }
};

export const getVideoInformation = (
  videoSpecification: VideoSpecificationArrayType[],
  key: string,
) => {
  return videoSpecification
    ?.find((res) => res?.key === key)
    ?.value?.map((item) => {
      const string = `${
        item.resolution !== null && item.resolution !== 0
          ? item.resolution > 1080 && item.resolution % 540 === 0
            ? `${Number(item.resolution) / 540}K`
            : `${item.resolution}p`
          : ''
      }${removeNull(item.frame_rate, '')}${removeNull(item.mode, '')}`;
      return string;
    })
    .filter(Boolean)
    .join(', ');
};

export const getCommunicationPositioningInformation = (
  communicationSpecification: CommunicationPositioningArray[],
  key: string,
) => {
  return communicationSpecification
    ?.find((res) => res?.key === key)
    ?.value?.map((item) => {
      const string = `${removeNull(
        item?.satellite_navigation_system?.trim(),
        '',
      )}${removeNull(item?.frequency_bands?.trim(), '') && ' '}${removeNull(
        item?.frequency_bands?.trim(),
        '',
      )}`;
      return string;
    })
    .filter(Boolean)
    .join(', ');
};

export const getDeviceFeatureAndSensors = (
  featuresAndSensors: FeatureItem[],
  key: string,
) => {
  return featuresAndSensors
    ?.find((res) => res?.key === key)
    ?.value?.map((item) => {
      const string = `${item.name}`;
      return string;
    })
    .filter(Boolean)
    .join(', ');
};

export const getDeviceOtherFeatures = (
  deviceExtraFeatures: FeatureItem[],
  key: string,
) => {
  const featuresOther =
    deviceExtraFeatures?.find((res) => res?.key === key)?.value || [];

  if (featuresOther.length > 0 || featuresOther !== null) {
    return featuresOther.map((featOtherObj) => featOtherObj.name).join(', ');
  }
  return '';
};

export const getExtraOtherDataForDisplay = (
  extraDisplaySpecs: FeatureItem[],
  key: string,
) => {
  const display_other =
    extraDisplaySpecs?.find((res) => res?.key === key)?.value || [];

  if (display_other?.length > 0 || display_other !== null) {
    return display_other
      ?.map((displayOtherObj) => displayOtherObj.name)
      .join(', ');
  }
  return '';
};

export const getNetworkFieldsData = (res: any) => {
  return moveObjectToLast(moveObjectToLast(res.data, 0), 0)?.map(
    (item: any) => {
      let string: any = [];
      if (Array.isArray(item.value)) {
        if (item.key !== 'technology_name' && item.key !== 'network_speed') {
          string = item.value.map((bandItem: any) => [
            `${removeNull(bandItem?.bands, ' ')} ${bandItem?.bandwidth
              ?.map((bwItem: any) => bwItem?.bandwidth)
              .join(
                item.key !== 'network_2g_bands' &&
                  item.key !== 'network_3g_bands'
                  ? ', '
                  : ' / ',
              )}${
              removeNull(bandItem?.band_model, '') ? ' - ' : ''
            }${removeNull(bandItem?.band_model, '')}`,
          ]);
        } else if (item.key === 'network_speed') {
          string = item.value
            .map(
              (speed: {
                type: string | null;
                download_speed: string | number | null;
                upload_speed: string | number | null;
              }) => {
                const isBothAvailable =
                  removeNull(speed.download_speed, '') &&
                  removeNull(speed.upload_speed, '');
                return ` ${removeNull(speed.type, '')}${
                  isBothAvailable ? '(' : ''
                }${
                  removeNull(speed.download_speed, '') ? '￬' : ''
                }${removeNull(speed.download_speed, '')}${
                  isBothAvailable ? ' / ' : ''
                }${
                  removeNull(speed?.upload_speed, '') ? '￪' : ''
                }${removeNull(speed?.upload_speed, '')}${
                  isBothAvailable
                    ? ' Mbps) '
                    : removeNull(speed.download_speed, '') ||
                        removeNull(speed.upload_speed, '')
                      ? ' Mbps'
                      : ''
                }`;
              },
            )
            .map((res: any) => res?.trim())
            .join(', ');
        } else {
          string = String(
            item.value
              .map((item: any) => removeNull(item?.name, ''))
              .join(' / '),
          );
        }
      } else {
        if (item.key === 'network_gprs' || item.key === 'network_edge') {
          string = item?.value ? 'Yes' : item?.value === false ? 'No' : '';
        } else {
          string = removeNull(item?.value, '');
        }
      }
      return {
        ...item,
        value: string,
      };
    },
  );
};
