import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { currencyLocaleType } from '@/Configuration/array';
import { Context } from '@/pages/_app';
import ErrorBoundary from '@/pages/ErrorBoundary';
import Cookies from 'js-cookie';
import { Typography } from '@mui/material';
import { getCountryCode } from '@/utils/common-service';
import { priceEnum } from '@/Interface/interface';

export let headerReqCountryCode: { country: string } = {
  country: '',
};
function CurrencyDropDown(props: {
  setIsMenuShow: Dispatch<SetStateAction<boolean>>;
}) {
  const { currencyDropDownValue, setCurrencyDropDownValue } =
    useContext(Context);

  const [countryCodeAlpha2, setCountryCodeAlpha2] = useState('US');

  const handleChange = (event: SelectChangeEvent) => {
    // setCurrencyDropDownChange(true);
    setCurrencyDropDownValue(event.target.value as keyof typeof priceEnum);
    setCountryCodeAlpha2(event.target.value);
    Cookies.set('CountryCode', event.target.value, { expires: 7 });
    setTimeout(() => {
      setCountryCodeAlpha2(event.target.value);
    }, 100);
    props.setIsMenuShow(false);
  };

  useEffect(() => {
    const countryCode = getCountryCode();
    setCurrencyDropDownValue(countryCode);
    setCountryCodeAlpha2(countryCode);
  }, []);

  return (
    <ErrorBoundary>
      <Box padding={{ md: 0, xs: '15px' }}>
        <FormControl>
          <Select
            className="select-test"
            id="country-select-box"
            value={countryCodeAlpha2}
            onChange={handleChange}
            onFocusCapture={(e) => {
              if (e.target.children[0]?.tagName === 'svg') {
                document.body.className = 'overflow-auto';
              }
            }}
            MenuProps={{
              disableScrollLock: true,
            }}
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '4px 10px',
                backgroundColor: 'white',
                display: 'flex !important',
                alignItems: 'center !important',
                color: 'var(--blue)',
                fontWeight: '600',
              },
              '& .MuiList-root': {
                display: 'flex !important',
                alignItems: 'center !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiSvgIcon-root': {
                right: '4px',
              },
            }}
          >
            {Object.keys(currencyLocaleType)?.length &&
              Object.keys(currencyLocaleType)?.map((countryCodeAlpha3, i) => {
                return (
                  <MenuItem
                    aria-label="currency"
                    className="country-item"
                    value={currencyLocaleType[countryCodeAlpha3].country}
                    key={i}
                    sx={{
                      color:
                        currencyLocaleType[countryCodeAlpha3]?.country ===
                        currencyDropDownValue
                          ? '#0063D1'
                          : '',
                      fontSize: '14px',
                      padding: '5px 10px',
                      fontWeight: '600',
                    }}
                  >
                    <Typography
                      sx={{
                        minWidth: '18px',
                        fontSize: '14px',
                        lineHeight: 'initial',
                        fontWeight: '600',
                      }}
                    >
                      {currencyLocaleType[countryCodeAlpha3].symbol}{' '}
                    </Typography>
                    {countryCodeAlpha3}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </ErrorBoundary>
  );
}

export default CurrencyDropDown;
